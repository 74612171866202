
import Navbar from "./navbar";
 
const Header = () => {
    return (
        <div className="header">
            <p>Lighthouse Lapphunds</p>
            <Navbar />
        </div>
    );
};
 
export default Header;